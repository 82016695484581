import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import team5 from "../../assets/images/team/ali-azim.jpg";
import team6 from "../../assets/images/team/ali-saadat.jpg";
import team7 from "../../assets/images/team/alireza-rahanjam.jpg";

const teamMembers = [
  {
    id: 1,
    name_en: "Ali Azimoshan",
    name_fa: "علی عظیم الشان",
    image: team5,
    position_en: "Developer",
    position_fa: "توسعه‌دهنده",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 2,
    name_en: "Ali Saadat",
    name_fa: "علی سعادت",
    image: team6,
    position_en: "Manager",
    position_fa: "خدای ویکافه",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 3,
    name_en: "Alireza Rahanjam",
    name_fa: "علیرضا ره‌انجام",
    image: team7,
    position_en: "Supervisor",
    position_fa: "سلطان سالن",
    caption_en: "",
    caption_fa: "",
  },
];

const Team = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "fa"; // Detect RTL language

  return (
    <React.Fragment>
      {/* Team */}
      <section
        className={`section softwere-team ${isRTL ? "rtl" : ""}`}
        id="team"
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title text-uppercase">
                  {t("team-title")}
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  {t("team-desc")}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="pt-0">
            {teamMembers.map((member) => (
              <Col lg={4} key={member.id} className="mt-3">
                <div className="softwere-team-box mt-5">
                  <div className="softwere-team-img">
                    <Image src={member.image} alt="" fluid />
                  </div>
                  <div className="softwere-team-content text-center mt-4 p-2">
                    <h4 className="software-team-title text-uppercase">
                      {isRTL ? member.name_fa : member.name_en}
                    </h4>
                    <p className="software-team-position mt-3">
                      {isRTL ? member.position_fa : member.position_en}
                    </p>
                    <p className="caption mt-3">
                      {isRTL ? member.caption_fa : member.caption_en}
                    </p>

                    <div className="mt-4 text-center">
                      <ul className="list-inline softwere-team-social mb-0">
                        <li className="list-inline-item mx-3">
                          <Link to="#" className="rounded-circle">
                            <i className="mdi mdi-facebook"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item ml-2 mx-3">
                          <Link to="#" className="rounded-circle">
                            <i className="mdi mdi-twitter"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item ml-2 mx-3">
                          <Link to="#" className="rounded-circle">
                            <i className="mdi mdi-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <hr />
    </React.Fragment>
  );
};

export default Team;
