import React from "react";
import "./App.css";
import Routing from "./routes";

import "./assets/css/materialdesignicons.min.css";
import "./assets/css/pe-icon-7-stroke.min.css";
import "./assets/css/bootstrap.min.css";
import { LanguageProvider } from "./context/LanguageContext";

const App = () => {
  return (
    <React.Fragment>
      <LanguageProvider>
        <Routing />
      </LanguageProvider>
    </React.Fragment>
  );
};

export default App;
