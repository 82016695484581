// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import faTranslations from './locales/fa/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      fa: {
        translation: faTranslations,
      },
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language in case translation key doesn't exist in selected language
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
  });

export default i18n;
