import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdLocationOn, MdPhone, MdAccessTime, MdEmail } from "react-icons/md";

const Contact: React.FC = () => {
  const { t } = useTranslation();

  const [errorMsg, setErrorMsg] = useState("");

  const handleInputChange = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    // Access form field values
    const name = formData.get("name");
    const email = formData.get("email");
    const number = formData.get("number");
    const comments = formData.get("comments");

    const validationError = validateForm(name, email, number, comments);
    if (validationError) {
      setErrorMsg(validationError);
      return;
    }
    form.reset();
    setErrorMsg("");
  };

  const validateForm = (name: any, email: any, number: any, comments: any) => {
    if (!name || name.trim() === "") {
      return t("validation-name");
    }
    if (!email || email.trim() === "") {
      return t("validation-email");
    }
    if (!number || number.trim() === "") {
      return t("validation-number");
    }
    if (!comments || comments.trim() === "") {
      return t("validation-comments");
    }
    return "";
  };

  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="home-title text-center">
                <h2 className="heading-title-title text-uppercase">
                  {t("contact-title")}
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  {t("contact-desc")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 d-flex align-items-start">
            <Col
              lg={5}
              className="d-flex flex-column justify-content-center mt-lg-5"
              style={{ height: "100%" }}
            >
              <div>
                {[
                  {
                    icon: <MdLocationOn size={22} />,
                    text: t("contact-address"),
                    href: "https://maps.app.goo.gl/YPXCi2WFugX85NETA",
                  },
                  {
                    icon: <MdPhone size={22} />,
                    text: t("contact-phone"),
                    href: "tel:+989126833175",
                  },
                  {
                    icon: <MdAccessTime size={22} />,
                    text: t("contact-working-time"),
                  },
                  {
                    icon: <MdEmail size={22} />,
                    text: t("contact-email"),
                    href: "mailto:xteamcoffee@gmail.com",
                  },
                ].map((item, index) => (
                  <div
                    className="d-flex align-items-center mb-4 agency-contact"
                    style={{ gap: "1rem" }}
                    key={index}
                  >
                    <div className="agency-contact-icon text-primary">
                      {item.icon}
                    </div>
                    {item.href ? (
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="base-color hover-effect"
                      >
                        {item.text}
                      </a>
                    ) : (
                      <p className="text-muted mb-0">{item.text}</p>
                    )}
                  </div>
                ))}
              </div>
            </Col>

            <Col lg={7}>
              <Form className="custom-form" onSubmit={handleInputChange}>
                {errorMsg && (
                  <Alert variant="danger" className="error_message">
                    {errorMsg}
                  </Alert>
                )}
                <Row>
                  <Col lg={12}>
                    <Form.Group className="my-3">
                      <Form.Control
                        name="name"
                        id="name"
                        type="text"
                        placeholder={t("contact-name-placeholder")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Control
                        name="email"
                        id="email"
                        type="email"
                        placeholder={t("contact-email-placeholder")}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="my-3">
                      <Form.Control
                        name="number"
                        id="number"
                        type="number"
                        placeholder={t("contact-number-placeholder")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="my-3">
                      <Form.Control
                        name="comments"
                        id="comments"
                        as="textarea"
                        rows={5}
                        placeholder={t("contact-comments-placeholder")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mt-3">
                      <Button
                        type="submit"
                        id="submit"
                        name="send"
                        className="submitBnt btn btn-md btn-dark-custom"
                      >
                        {t("contact-submit")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
