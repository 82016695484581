// src/Index.tsx
import React from "react";
import Navbar from "../layout/Navbar";
import Header from "../components/Header";
import NeedUs from "../components/About";
import Service from "../components/Service";
import Project from "../components/Project";
import Experience from "../components/Experience";
import Team from "../components/Team";
import Contact from "../components/Contact";
import Footer from "../layout/Footer";

const Index = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Header />
      <NeedUs />
      <Service />
      <Project />
      <Experience />
      <Team />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Index;
