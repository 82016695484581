import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaInstagram, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  const { t } = useTranslation();

  const phoneNumber = "+989126833175"; // Update with your number
  const instagramUsername = "xteam.coffee";
  const whatsappNumber = "+989126833175";

  return (
    <React.Fragment>
      <footer className="section-footer bg-softwere-footer">
        <Container>
          <Row>
            {/* Follow Us on Social Media */}
            <Col lg={4}>
              <div className="softwere-footer p-4 mt-4">
                <div className="softwere-footer-icon">
                  <a
                    href={`https://instagram.com/${instagramUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    <FaInstagram size={30} />
                  </a>
                </div>
                <h4 className="text-uppercase f-15 text-white mt-4">
                  {t("footer-followUs")}
                </h4>
                <p className="text-muted mt-4 f-15 line-height_1_6">
                  {t("footer-followUsDescription")}
                </p>
                <p className="text-muted mt-4 f-15 line-height_1_6">
                  <a
                    href={`https://instagram.com/${instagramUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    @{instagramUsername}
                  </a>
                </p>
              </div>
            </Col>

            {/* Call Us Section */}
            <Col lg={4}>
              <div className="softwere-footer p-4 mt-4">
                <div className="softwere-footer-icon">
                  <a href={`tel:${phoneNumber}`} className="text-white">
                    <FaPhoneAlt size={30} />
                  </a>
                </div>
                <h4 className="text-uppercase f-15 text-white mt-4">
                  {t("footer-callUs")}
                </h4>
                <p className="text-muted mt-4 f-15 line-height_1_6">
                  {t("footer-callUsDescription")}
                </p>
                <p className="text-muted mt-4 f-15 line-height_1_6">
                  <a href={`tel:${phoneNumber}`} className="text-white">
                    {t("footer-phoneNumber")}
                  </a>
                </p>
              </div>
            </Col>

            {/* Chat with Us Section */}
            <Col lg={4}>
              <div className="softwere-footer p-4 mt-4">
                <div className="softwere-footer-icon">
                  <a
                    href={`https://wa.me/${whatsappNumber.replace("+", "")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    <FaWhatsapp size={30} />
                  </a>
                </div>
                <h4 className="text-uppercase f-15 text-white mt-4">
                  {t("footer-chatWithUs")}
                </h4>
                <p className="text-muted mt-4 f-15 line-height_1_6">
                  {t("footer-whatsappDescription")}
                </p>
                <p className="text-muted mt-4 f-15 line-height_1_6">
                  <a
                    href={`https://wa.me/${whatsappNumber.replace("+", "")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    {t("footer-whatsappNumber")}
                  </a>
                </p>
              </div>
            </Col>
          </Row>

          {/* Footer Bottom Section */}
          <Row className="mt-4 py-4">
            <Col lg={12}>
              <p
                dir="ltr"
                className="copy-rights text-muted mb-0 text-center"
                style={{ fontFamily: "'Raleway', sans-serif" }}
              >
                &copy; {new Date().getFullYear()} Xteam. Design by{" "}
                <a
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://aliazimoshan.info"
                >
                  AliAzim 👽
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
