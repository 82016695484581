import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/LanguageContext";
import useScroll from "./useScroll";

const LanguageSwitcher = () => {
  const navClass = useScroll();

  const { i18n } = useTranslation();
  const { language, toggleLanguage } = useLanguage();

  const handleLanguageChange = () => {
    i18n.changeLanguage(language === "en" ? "fa" : "en");
    toggleLanguage();
    console.log(navClass);
  };

  // Example of updating dir on language switch
  useEffect(() => {
    if (language === "fa") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [language]);

  return (
    <div>
      <button
        className={`language-switcher-btn ${
          navClass === "nav-sticky" ? "dark" : "light"
        }`}
        onClick={handleLanguageChange}
      >
        {language === "en" ? "FA" : "EN"}
      </button>
    </div>
  );
};

export default LanguageSwitcher;
