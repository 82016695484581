import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import vcafeRoof from "../../assets/images/background/vcafe-roof.jpeg";

const NeedUs = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/* About */}
      <section className="section about" id="about">
        <Container className="interior-about-content">
          <Row className="align-items-center justify-content-around">
            <Col lg={5}>
              <h4 className="text-uppercase about-header">
                {t("about-title")}
              </h4>
              <p className="mt-4 mb-4 caption">{t("about-desc")}</p>
            </Col>

            <Col lg={5}>
              {/* <div className="interior-about-img"> */}
              <img src={vcafeRoof} className="img-fluid" alt="" />
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </section>

      <hr />
    </React.Fragment>
  );
};

export default NeedUs;
