// src/context/LanguageContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

type LanguageContextType = {
  language: "en" | "fa";
  toggleLanguage: () => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<"en" | "fa">("en");

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "fa" : "en"));
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
