import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Image
import work1 from "../../assets/images/works/work-1.jpg";
import work2 from "../../assets/images/works/work-2.jpg";
import work3 from "../../assets/images/works/work-3.jpg";
import work4 from "../../assets/images/works/work-4.jpg";
// import work5 from "../../assets/images/works/work-5.jpg";
// import work6 from "../../assets/images/works/work-6.jpg";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";

interface PortfolioData {
  image: string;
  groups: string[];
  title: string;
  category: string;
}

const portfolioData: PortfolioData[] = [
  {
    image: work1,
    groups: ["Cafe"],
    title: "Vcafe",
    category: "Cafe",
  },
  {
    image: work2,
    groups: ["Bakery"],
    title: "Patis",
    category: "Bakery",
  },
  {
    image: work3,
    groups: ["Gym"],
    title: "Sam Gym",
    category: "Gym",
  },
  {
    image: work4,
    groups: ["Cafe"],
    title: "Ferment",
    category: "Cafe",
  },
];

const Project: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const [filter, setFilter] = React.useState("All");

  const handleFilter = (group: any) => {
    setFilter(group);
  };

  const filteredData =
    filter === "All"
      ? portfolioData
      : portfolioData.filter((item) => item.groups.includes(filter));

  return (
    <React.Fragment>
      <section className="section portfolio business-portfolio" id="project">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h2 className="heading-title-title text-uppercase">
                  {t("project-title")}
                </h2>
                <p className="heading-title-desc text-muted mt-4 mb-0">
                  {t("project-desc")}
                </p>
              </div>
            </Col>
            <Col lg={8} className="mt-5">
              <div className="filters-group-wrap ">
                <div className="filters-group">
                  <Nav className="filter-options justify-content-center mt-3">
                    <li
                      onClick={() => handleFilter("All")}
                      className={` nav-link list-inline-item  ${
                        filter === "All" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {language === "en" ? "All" : "همه"}
                    </li>
                    <li
                      onClick={() => handleFilter("Cafe")}
                      className={`nav-link list-inline-item ${
                        filter === "Cafe" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {language === "en"
                        ? "Cafe & Restaurant"
                        : "کافه و رستوران"}
                    </li>
                    <li
                      onClick={() => handleFilter("Bakery")}
                      className={`nav-link list-inline-item ${
                        filter === "Bakery" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {language === "en" ? "Bakery" : "بیکری"}
                    </li>
                    <li
                      onClick={() => handleFilter("Gym")}
                      className={`nav-link list-inline-item ${
                        filter === "Gym" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {language === "en" ? "Gym" : "باشگاه"}
                    </li>
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3">
          <Row id="grid">
            {(filteredData || [])?.map((item: PortfolioData, key: number) => (
              <Col
                lg={4}
                key={key}
                className="picture-item"
                data-groups={JSON.stringify(item.groups)}
              >
                <div className="gallary-box m-3 portfolio">
                  <Link className="lightbox port" to="#" title="">
                    <img
                      className="gallary-container"
                      src={item.image}
                      alt={item.title}
                    />
                    <div className="item-effect">
                      <div className="item-caption text-center bg-primary text-white p-3">
                        <h5 className="f-14 text-uppercase mb-0">
                          {item.category}
                        </h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Project;
