import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";

interface ServicesData {
  icon: string;
  title: any;
  description: any;
}

const servicesData: ServicesData[] = [
  {
    icon: "pe-7s-display1",
    title: { en: "Web Development", fa: "توسعه وب" },
    description: {
      en: "We create responsive, high-performing websites tailored to your needs. Empower your online presence with modern web solutions.",
      fa: "ما وب‌سایت‌هایی واکنش‌گرا و با عملکرد بالا برای نیازهای شما ایجاد می‌کنیم. حضور آنلاین خود را با راه‌حل‌های وب مدرن تقویت کنید.",
    },
  },
  {
    icon: "pe-7s-pen",
    title: { en: "Graphic Design", fa: "طراحی گرافیک" },
    description: {
      en: "Stunning designs that captivate your audience. From branding to marketing, we bring your vision to life.",
      fa: "طراحی‌های جذابی که مخاطبان شما را شگفت‌زده می‌کند. از برندینگ تا بازاریابی، دیدگاه شما را به واقعیت تبدیل می‌کنیم.",
    },
  },
  {
    icon: "pe-7s-phone",
    title: { en: "App Development", fa: "توسعه اپلیکیشن" },
    description: {
      en: "Build cutting-edge mobile applications. Custom solutions for iOS and Android to enhance user experiences.",
      fa: "ایجاد اپلیکیشن‌های موبایل پیشرفته. راه‌حل‌های اختصاصی برای iOS و Android جهت بهبود تجربه کاربران.",
    },
  },
  {
    icon: "pe-7s-portfolio",
    title: { en: "Software Development", fa: "توسعه نرم‌افزار" },
    description: {
      en: "Custom software tailored to your business needs. Scalable and efficient solutions for modern enterprises.",
      fa: "نرم‌افزارهای سفارشی مطابق با نیازهای کسب‌وکار شما. راه‌حل‌های مقیاس‌پذیر و کارآمد برای شرکت‌های مدرن.",
    },
  },
  {
    icon: "pe-7s-compass",
    title: { en: "Digital Marketing", fa: "بازاریابی دیجیتال" },
    description: {
      en: "Expand your reach with result-driven marketing strategies. From SEO to social media, grow your brand online.",
      fa: "با استراتژی‌های بازاریابی مبتنی بر نتایج، دامنه خود را گسترش دهید. از سئو تا شبکه‌های اجتماعی، برند خود را آنلاین رشد دهید.",
    },
  },
  {
    icon: "pe-7s-display2",
    title: { en: "UI & UX Design", fa: "طراحی رابط و تجربه کاربری" },
    description: {
      en: "Craft intuitive user interfaces and seamless experiences. Elevate user engagement with elegant design.",
      fa: "رابط‌های کاربری شهودی و تجربه‌های بدون نقص طراحی کنید. با طراحی شیک تعامل کاربران را بهبود دهید.",
    },
  },
];

const Service = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <React.Fragment>
      {/* Services */}
      <section className="section service" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title text-uppercase">
                  {t("service-title")}
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  {t("service-desc")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            {(servicesData || []).map((item: ServicesData, index: number) => (
              <Col lg={4} key={index}>
                <div className="agency-services-box text-center p-2">
                  <div className="agency-services-icon mt-3">
                    <i className={item.icon}></i>
                  </div>
                  <h5 className="service-item-header mt-4 text-uppercase">
                    {language === "fa" ? item.title.fa : item.title.en}
                  </h5>
                  <p className="mt-4 heading-title-desc text-muted">
                    {language === "fa"
                      ? item.description.fa
                      : item.description.en}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <hr />
    </React.Fragment>
  );
};

export default Service;
