import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const counterData = [
  { end: 10, label: "Projects", id: "count1" },
  { end: 150, label: "Clients", id: "count2" },
  { end: 10, label: "Projects", id: "count3" },
  { end: 150, label: "Clients", id: "count4" },
];

const Experience = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/* Counter */}
      <section className="section business-bg-counter" id="experience">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col lg={5}>
              <div className="business-counter-content text-white">
                {/* <div className="business-counter-icon">
                  <i className="mdi mdi-account-multiple-plus-outline"></i>
                </div> */}
                <h1 className="line-height_1_4 mt-2">
                  {t("experience-title")}
                </h1>
                <p className="business-counter-desc mt-4">
                  {t("experience-desc")}
                </p>
              </div>
            </Col>

            <Col lg={6} className="offset-lg-1">
              <Row id="counter">
                {counterData.map((item) => (
                  <Col key={item.id} lg={6} xs={6} className="mt-4">
                    {" "}
                    {/* Adds 2 columns per row on mobile */}
                    <div className="business-counter-box text-center text-white">
                      <h2 className="counter-count mt-4 text-white">
                        <CountUp
                          start={0}
                          end={item.end}
                          duration={2}
                          style={{ fontSize: "26px" }}
                        />
                      </h2>
                      <p className="text-uppercase business-counter-item-title">
                        {item.label}
                      </p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Experience;
