import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {/* Home */}
      <section className="section construction-home" id="home">
        <div className="bg-overlay"></div>
        <div className="home-center">
          <div className="home-desc-center">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="construction-home-content text-center text-white">
                    <h4 className="construction-home-title line-height_1_6 text-uppercase">
                      {t("header-title")}
                      <br />
                      {t("header-title-2")}
                    </h4>
                    <div className="construction-home-border mt-4"></div>
                    <p className="construction-home-desc f-16 mt-4 mx-auto line-height_1_8">
                      {t("header-desc")}
                    </p>
                    <div className="mt-4 pt-3 construction-home-btn">
                      <Button href="#about" variant="md" className="btn-custom">
                        {t("header-button")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Header;
